<div class="container" *ngIf="action == 'create'">
    <h1 class="fs-2 fw-light mt-4">Add Fee</h1>
    <form [formGroup]="addFeeForm" >

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }"/>
        </div>

        <div class="form-group my-4">
            <mat-form-field appearance="fill">
                <mat-label>Choose a period</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="period" (dateChange)="appendDescription($event)"  >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label class="form-label">Fee Type</label>
            <select class="form-select form-control" formControlName="obligationTypeId" readonly>
                <option value="1">Maintenance</option>
                <option value="2">Fee</option>
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lot/Unit Number</label>
            <input class="form-control col-3"  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Description</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="description" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }"/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden"  formControlName="propertyId" readonly/>
        </div>

       
    </form> 
</div>
<div class="container" *ngIf="action == 'edit'">
    <h1 class="fs-2 fw-light mt-4">Edit Fee</h1>
    <form [formGroup]="editFeeForm" >
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Period</label>
            <input class="form-control col-3"  type="text" placeholder="Period" formControlName="period" [ngClass]="{ 'is-invalid': submitFeeData && foa.period.errors, 'is-valid': foa.period.valid && foa.period.touched }"/>
        </div> -->
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }" readonly/>
        </div>

        <div class="form-group my-4">
            <mat-form-field appearance="fill">
                <mat-label>Choose a period</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="period" (dateChange)="appendDescription($event)"  >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group">
            <label class="form-label">Fee Type</label>
            <select class="form-select form-control" formControlName="obligationTypeId" readonly>
                <option value="1">Maintenance</option>
                <option value="2">Fee</option>
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lot/Unit Number</label>
            <input class="form-control col-3"  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Description</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="description" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }"/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden"  formControlName="propertyId" readonly/>
        </div>

       
    </form> 
    
</div>
<div class="container" *ngIf="action == 'view'">
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="fs-2 fw-light mt-4">View Fee</h1>
        <button (click)="onEditFee()" class="mt-4 btn btn-primary">Edit <fa-icon [icon]="faPenToSquare"></fa-icon></button>
    </div>
    <form [formGroup]="viewFeeForm" >
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Period</label>
            <input class="form-control col-3"  type="text" placeholder="Period" formControlName="period" [ngClass]="{ 'is-invalid': submitFeeData && foa.period.errors, 'is-valid': foa.period.valid && foa.period.touched }"/>
        </div> -->
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }" />
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="period">Fee Date</label>
            <input class="form-control col-3"  type="text" placeholder="January-1-2020" formControlName="period"  />
        </div>

        <div class="form-group">
            <label class="form-label">Fee Type</label>
            <select class="form-select form-control" formControlName="obligationTypeId">
                <option value="1">Maintenance</option>
                <option value="2">Fee</option>
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="unitNumber">Lot/Unit Number</label>
            <input class="form-control col-3 "  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="unitNumber">Description</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="description" [ngClass]="{ 'is-invalid': submitFeeData && foa.amount.errors, 'is-valid': foa.amount.valid && foa.amount.touched }"/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitFeeData && foa.premiseId.errors, 'is-valid': foa.premiseId.valid && foa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden"  formControlName="propertyId" readonly/>
        </div>

       
    </form> 
</div>
<div class="container" *ngIf="action == 'list'">
    <h1 class="fs-2 fw-light mt-4">List</h1>
</div>

<div class="mt-4 d-flex container justify-content-between align-items-center">
    <button type="button" (click)="goBack()" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>

    <button *ngIf="action == 'create'" [disabled]="fetching" class="btn btn-primary" (click)="createFee()" >
        Save <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>
    
    </button>
</div>


