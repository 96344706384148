import { CurrencyPipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Obligation } from '@app/_models/obligation';
import { Owner } from '@app/_models/owner';
import { Payment } from '@app/_models/payment';
import { UserService } from '@app/_services';
import { FinancialService } from '@app/_services/financial.service';
import { NotificationService } from '@app/_services/notification.service';
import { PremiseService } from '@app/_services/premise.service';
import { PropertyService } from '@app/_services/property.service';
import { environment } from '@environments/environment';
import {  faCircleArrowLeft,  faAngleDown, faAngleUp, faHomeAlt, faEye, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-premises',
  templateUrl: './premises.component.html',
  styleUrls: ['./premises.component.css']
})
export class PremisesComponent {

  editPremisesForm!: FormGroup;

  ownerData!: Owner[];

  faCircleArrowLeft = faCircleArrowLeft;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faHomeAlt = faHomeAlt;
  faEye = faEye;
  faInfoCircle = faInfoCircle;

  obligations!: Obligation[];
  payments!: Payment[];
  fees!: Obligation[];
  maintenances!: Obligation[];

  maintenanceoutstanding = 0;
  paymnentstotal = 0;
  feestotal = 0;

  paymentsview = false;
  feesview = false;
  maintenanceview = false;

  selectedPremise: any;
  property: any;

  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private premiseService: PremiseService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private propertyService: PropertyService,
    private financialService: FinancialService,
    private userService: UserService,
    private sendNotificationService: NotificationService,
    private _location: Location,
    private router: Router) {
      
    }

    get ep() { return this.editPremisesForm.controls; }

    viewPayment(id: number) {
      console.log('Payment id:::::',id);
      this.financialService.getPayment(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Payment data fetched: ', data);
          }
          this.financialService.setPayment(data);
  
          this.router.navigate(['/manage-payments/view']);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch payment data: ', error);
          }
        }
        
      });
  
  
      
    }
  
    viewFee(id: number) {
      
      this.financialService.getObligation(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Obligation fee data fetched: ', data);
          }
          this.financialService.setFee(data);
  
          this.router.navigate(['/manage-fees/view']);
  
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch obligation fee data: ', error);
          }
        }
        
      });
    }
  
    viewObligation(id: number) {
      this.financialService.getObligation(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Obligation data fetched: ', data);
          }
          this.financialService.setObligation(data);
  
          this.router.navigate(['/manage-maintenance/view']);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch obligation data: ', error);
          }
        }
        
      });
      
    }

    ngOnInit(): void {
      this.loadPremiseData();
    }
  
    loadPremiseData() {
      //Check user associations to premises

      //present with list or go directly to single premises
      
      this.premiseService.fetchPremiseData(this.selectedPremise.unit_number, this.selectedPremise.propertyId).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Result::: Fetch: Premise data: ', data);
          }
          this.ownerData = data.owners || [];
          this.obligations = data.obligations || [];
  
          this.maintenanceoutstanding = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false).reduce(function(curr, prev) {
            return curr + parseFloat(prev.amount.toString());
            
          }, 0);
  
          this.feestotal = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2).reduce(function(curr, prev) {
            return curr + parseFloat(prev.amount.toString());
            
          }, 0);
  
          this.fees = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2);
  
          this.maintenances = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false);
  
          this.payments = data.payments || [];
  
          this.paymnentstotal = this.payments.reduce(function(curr, prev) {
            return curr + parseFloat(prev.amount.toString());
          }, 0);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Error: Fetch Premise Data::: ', error);
          }
        }
      })
    }

    toggleIcon(display: string) {
      if (display == 'payments') {
        this.paymentsview = !this.paymentsview;
      }
  
      if (display == 'maintenance') {
        this.maintenanceview = !this.maintenanceview;
      }
  
      if (display == 'fees') {
        this.feesview = !this.feesview;
      }
    }

    goHome(): void {
      this.router.navigate(['/home'])
    }

      goBack() {
    this._location.back();
  }
  
  
}
