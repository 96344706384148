import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { environment } from '@environments/environment';
import { faSignInAlt, faUserPlus, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string | undefined;
  error = '';

  pastLogin = false;

  faSignInAlt = faSignInAlt;
  faUserPlus = faUserPlus;
  faTachometerAlt = faTachometerAlt;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private toastr: ToastrService,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
          this.router.navigate(['/home']);
      }
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      this.pastLogin = localStorage.getItem('offlineUser') != undefined && localStorage.getItem('offlineUser')!.includes('username');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  openRegistration() {
      this.router.navigate(['/register']);
  }

  openForgetPassword() {
      this.router.navigate(['/forget-password']);
  }

  openQueryMaintenance() {
      //check localstorage

     this.router.navigate(['/query-maintenance']);
  }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      this.authenticationService.login(this.f.username.value, this.f.password.value)
          //.pipe(first())
          .subscribe(
            {
              complete: () => {
                  
                  if (!environment.production) {
                    console.log('Complete block activated');
                  }
              },
              next: (data) => {
                  if (!environment.production) {
                    console.log('Token value: ', data.access_token);
                  }
                  
                  if (data.access_token) {
                      this.loading = false;
                      this.error = '';
                      this.toastr.success('Login successful.', 'Success');
                      this.router.navigate(['/home']);
                  } else {
                      this.toastr.error('Username/Password does not match', 'Error');
                      this.error = 'Username/Password does not match';
                      this.loading = false;
                  }
              },
              error: (error) => {
                  console.log('Error block activated');
                  this.toastr.error(error, 'Error');
                  this.error = error;
                  //this.toastr.error('Username/Password does not match', 'Error');
                  //this.error = 'Username/Password does not match';
                  this.loading = false;
              }
            }
              // (data) => {
              //     if (data.access_token) {
              //         this.loading = false;
              //         this.toasta.success({msg: 'Login successful.',title: 'Success'});
              //         this.router.navigate(['/home']);
              //     } else {
              //         this.toasta.error({msg: 'Username/Password does not match', title: 'Error'});
              //         this.error = 'Username/Password does not match';
              //         this.loading = false;
              //     }
                 
                               
              // },
              // error => {
              //     this.toasta.error({msg: 'Login failed.', title: 'Failed'});
              //     this.error = error;
              //     this.loading = false;
              // }
              
             
            );
  }


}
