<div class="container" *ngIf="action == 'create'">
    <h1 class="display-6">Create Property </h1>
    <form [formGroup]="addPropertyForm" >
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property Name</label>
            <input class="form-control col-3"  type="text" placeholder="Property name" formControlName="property_name" [ngClass]="{ 'is-invalid': submitPropertyData && fp.property_name.errors, 'is-valid': fp.property_name.valid && fp.property_name.touched }"/>
        </div>
        <div class="form-group">
            <label class="form-label">Property Type</label>
            <select class="form-select form-control" formControlName="property_type">
                <option value="strata">Strata</option>
                <option value="house">House</option>
                <option value="hotel">Hotel</option>
                <option value="Townhouse">Town House</option>
            </select>
        </div>
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Number of units</label>
            <input class="form-control" placeholder="Number of units/apartments" formControlName="number_units" [ngClass]="{ 'is-invalid': submitPropertyData && fp.number_units.errors, 'is-valid': fp.number_units.valid && fp.number_units.touched }"/>
        </div>
        <div class="form-group my-4">
            <button (click)="toggleAdvanced()" class="btn btn-primary">Advanced <fa-icon [icon]="faCog"></fa-icon></button>
        </div>
        <div *ngIf="advanced">
            <div class="form-group my-4">
                <label class="form-label">Units Per Floor</label>
                <input class="form-control"  type="text" placeholder="# Units per floor" formControlName="units_floor"/>
            </div>
            <div class="form-group">
                <label class="form-label" >Room/Entitlement Configuration</label>
                <table class="table table-striped table-bordered">
                    <thead>
                      <th scope="col"># of rooms</th>
                      <th scope="col">Unit of Entitlement</th>
                      <th scope="col">Option(s)</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let config of roomconfig">
                        <td>{{config.number_rooms}}</td>
                        <td>{{config.unit_entitlement}}</td>
                        <td><button (click)="onDeleteRoomConfig(config)" class="btn btn-sm btn-danger">Remove <fa-icon [icon]="faTimesCircle"></fa-icon></button></td>
                      </tr>
                      
                    </tbody>
              
                  </table>
            </div>
            
            <form [formGroup]="addRoomConfigForm" >
                <div class="form-group input-group my-4">
                    <input class="form-control" type="text" formControlName="number_rooms" placeholder="# of rooms" [ngClass]="{ 'is-invalid': submitRoomConfig && fr.number_rooms.errors, 'is-valid': fr.number_rooms.valid && fr.number_rooms.touched }" />
                    <input class="form-control" type="text" formControlName="unit_entitlement" placeholder="unit of entitlement" [ngClass]="{ 'is-invalid': submitRoomConfig && fr.unit_entitlement.errors, 'is-valid': fr.unit_entitlement.valid && fr.unit_entitlement.touched }" />
                    <button (click)="onAddRoomConfig()" class="btn btn-primary">Add <fa-icon [icon]="faPlusCircle"></fa-icon></button>
                </div>
            </form>
           
        </div>
        
        <div class="mt-4 d-flex justify-content-between align-items-center">
            <button (click)="onCancel()" type="button" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>

            <button [disabled]="fetching" class="btn btn-primary" (click)="createProperty()" >
                Create <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
                <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>
            
            </button>
        </div>

        
    </form>

    
</div>


<div class="container" *ngIf="action == 'edit'">
    <h1 class="display-6 mt-4">Edit Property</h1>
    <form [formGroup]="editPropertyForm" (ngSubmit)="onEditSave()">
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property Name</label>
            <input class="form-control col-3"  type="text" placeholder="Property name" formControlName="property_name" [ngClass]="{ 'is-invalid': submitPropertyData && fp.property_name.errors, 'is-valid': fp.property_name.valid && fp.property_name.touched }"/>
        </div>
        <div class="form-group">
            <label class="form-label">Property Type</label>
            <select class="form-select form-control" formControlName="property_type">
                <option value="strata">Strata</option>
                <option value="house">House</option>
                <option value="hotel">Hotel</option>
            </select>
        </div>
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Number of units</label>
            <input class="form-control" placeholder="Number of units/apartments" formControlName="number_units"/>
        </div> -->

        <div class="mt-4 d-flex justify-content-between align-items-center">
            <button (click)="onCancel()" class="btn btn-danger" type="button">Cancel <fa-icon [icon]="faTimesCircle"></fa-icon></button>
            <button class="btn btn-primary" type="submit">Save <fa-icon [icon]="faPaperPlane"></fa-icon></button>
        </div>
    </form>
</div>

<div class="container" *ngIf="action == 'view'">
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="fs-2 fw-light mt-4">Property Details</h1>
        <button (click)="onEditProperty()" class="mt-4 btn btn-primary">Edit <fa-icon [icon]="faPenToSquare"></fa-icon></button>
    </div>
    
    <form [formGroup]="viewPropertyForm" >
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property Name</label>
            <input class="form-control col-3"  type="text" placeholder="Property name" formControlName="property_name" [ngClass]="{ 'is-invalid': submitPropertyData && fp.property_name.errors, 'is-valid': fp.property_name.valid && fp.property_name.touched }" readonly/>
        </div>
        <div class="form-group">
            <label class="form-label">Property Type</label>
            <select class="form-control" formControlName="property_type" readonly>
                <option value="strata">Strata</option>
                <option value="house">House</option>
                <option value="Townhouse">Town House</option>
                <option value="hotel">Hotel</option>
            </select>
        </div>
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Number of units</label>
            <input class="form-control" placeholder="Number of units/apartments" formControlName="number_units" readonly/>
        </div>
        <div class="mt-4 d-flex justify-content-between align-items-center">
            <button (click)="onCancel()" type="button" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
            <button (click)="viewPremises()" type="button" class="btn btn-info">View Premises <fa-icon [icon]="faEye"></fa-icon></button>
        </div>
    </form>
</div>

<div class="container" *ngIf="action == 'list'">
    
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="fs-2 fw-light mt-4">Choose Property</h1>
        <button (click)="onCreateProperty()" class="mt-4 btn btn-primary">Add <fa-icon [icon]="faCirclePlus"></fa-icon></button>
    </div>

    <div class="form-group has-search">
        <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
        <input type="text" name="search" [(ngModel)]="searchProperty" class="form-control" placeholder="Search by property name">
    </div>

    <ul class="list-group list-group-flush col-md-6">
        <li (click)="onSelectedProperty(property)" class="fs-3 text-primary list-group-item list-group-item-action d-flex justify-content-between align-items-center" *ngFor="let property  of properties | filter: searchProperty ">
            <fa-icon *ngIf="property.property_type == 'house'" class="l-icon" [icon]="faHouse"></fa-icon> 
            <fa-icon *ngIf="property.property_type == 'strata'" class="l-icon" [icon]="faBuilding"></fa-icon> 
            <fa-icon *ngIf="property.property_type == 'Townhouse'" class="l-icon" [icon]="faHouseChimney"></fa-icon> 
            
            <span class="text-black fw-lighter">{{property.name}} </span>
            <fa-icon [icon]="faCircleArrowRight"></fa-icon>
        </li>

    </ul>

</div>

