import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, RoutesRecognized } from '@angular/router';

import { AuthenticationService } from '@app/_services';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }
    
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            
            if (route.data.roles) {
                if (!environment.production) {
                    console.log('Roles required: ', route.data.roles);
                    console.log('Current user role: ', currentUser.role);
                    console.log('Test: Length is 0', route.data.roles.filter((item: string) => currentUser?.role?.includes(item)).length == 0);
                }

                if (route.data.roles.filter((item: string) => currentUser?.role?.includes(item)).length == 0) {
                    this.router.navigate(['/']);
                    return false;
                }
               
            }
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}