import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
//import { User } from '@app/_models';
import { AuthResponse, MessageResponse } from '@app/_models/responses';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<AuthResponse | null>;
    private currentOfflineUserSubject: BehaviorSubject<AuthResponse | null>;
    //public currentUser: Observable<User>;
    public currentUser: Observable<AuthResponse | null>;

    constructor(private http: HttpClient, private toastr: ToastrService,
        private router: Router,
        ) {
        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        // console.log('actually', localStorage.getItem('currentUser'));
        //console.log('What is current User: ', localStorage.getItem('currentUser'));
        localStorage.getItem('currentUser') == "undefined" ?  localStorage.removeItem('currentUser') : console.log('User logged in');
        //let currUser = localStorage.getItem('currentUser') ?? '[]';
        this.currentUserSubject = new BehaviorSubject<AuthResponse | null>(JSON.parse(localStorage.getItem('currentUser')!));
        //let offUser = localStorage.getItem('offlineUser') ?? '[]';
        this.currentOfflineUserSubject = new BehaviorSubject<AuthResponse | null>(JSON.parse(localStorage.getItem('offlineUser')!));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    // public get currentUserValue(): User {
    //     return this.currentUserSubject.value;
    // }
    public get currentUserValue(): AuthResponse | null {
        return this.currentUserSubject.value;
    }

    public get currentOfflineUserValue(): AuthResponse | null {
        return this.currentOfflineUserSubject!.value;
    }

    refresh() {
        let header = new HttpHeaders();
        //let currentUser = this.currentUserValue;
        //header = header.append('content-type', 'application/json');

        if (this.currentUserValue?.refresh_token == undefined || this.currentUserValue?.refresh_token == null) {
            if (!environment.production) {
                console.log('Refresh token not present: ', this.currentUserValue?.refresh_token);          
            }
            this.currentUserSubject.next(null);
        }
        
        header = header.append('Authorization', `Bearer ${this.currentUserValue?.refresh_token}`);

        if (!environment.production) {
            console.log('Before refresh token: ', this.currentUserValue?.refresh_token);
            console.log('Refresh token: ', header);
        }

        return this.http.post<AuthResponse>(`${environment.apiAuthUrl}/auth/token/refresh`, {}, {headers: header})
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject!.next(user);

                return user;
            }))
    }

    verifyaccount(email: string, token: string) {
        return this.http.get<MessageResponse>(`${environment.apiAuthUrl}/auth/confirm/account/` + email + "/" + token);
    }


    login(email: string, password: string) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<AuthResponse>(`${environment.apiAuthUrl}/auth/login`, { email, password },{headers : header})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
                if (!environment.production) {
                    console.log('Please fix login, populate local storage even when value is error');
                }
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('offlineUser', JSON.stringify(user));
                this.currentUserSubject!.next(user)!;
                
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        this.toastr.success('User logged out succesfully','Logout');
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }

}