import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AppService {
    private buttonClicked$ = new BehaviorSubject<any>({});
    private recordId$ = new BehaviorSubject<any>({});

    selectedButton$ = this.buttonClicked$.asObservable();
    selectedRecordId$ = this.recordId$.asObservable();


    setButton(buttonid: any) {
        this.buttonClicked$.next(buttonid);
    }

    setRecordId(recordid: number) {
        this.recordId$.next(recordid);
    }


}