<div class="col-md-6 offset-md-3 mt-5">

    <div class="ng-container registration-logo">       
        <img src="assets/billdeo_logo.png">
    </div>
    <div class="card">
        <h4 class="card-header">Billdeo - Registration</h4>
        <div class="card-body">
            <form [formGroup]="registrationForm"  (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label class="sr-only" for="firstname">First Name</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faUser"></fa-icon></div>
                      </div>
                      <input type="text" formControlName="firstname" class="form-control py-0" name="firstname" id="firstname" [pattern]="namePattern" placeholder="First name" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors, 'is-valid': f.firstname.valid && f.firstname.touched }" >
                      <div *ngIf="f.firstname && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required">First name must be entered e.g John</div>
                      </div>
                      <div *ngIf="f.firstname && f.firstname.errors?.pattern " class="invalid-feedback">
                        First name must be entered in the format e.g John
                      </div> 
                    </div>
                </div>

                <div class="form-group">
                    <label class="sr-only" for="lastname">Last Name</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faUser"></fa-icon></div>
                      </div>
                      <input type="text" formControlName="lastname" class="form-control py-0" name="lastname" id="lastname" [pattern]="namePattern" placeholder="Last name" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors, 'is-valid': f.lastname.valid && f.lastname.touched }" >
                      <div *ngIf="f.lastname && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.lastname.errors.required">Last name be entered e.g Brown</div>
                      </div>
                      <div *ngIf="f.lastname && f.lastname.errors?.pattern " class="invalid-feedback">
                        Last name must be entered in the format e.g Brown
                      </div> 
                    </div>
                </div>

                <div class="form-group">
                  <label class="sr-only" for="username">Username</label>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><fa-icon [icon]="faUser"></fa-icon></div>
                    </div>
                    <input type="text" formControlName="username" class="form-control py-0" name="username" id="username" [pattern]="namePattern" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors, 'is-valid': f.username.valid && f.username.touched }" >
                    <div *ngIf="f.username && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username must be entered, no spaces</div>
                    </div>
                    <div *ngIf="f.username && f.username.errors?.pattern " class="invalid-feedback">
                      Username must be entered, no spaces
                    </div> 
                  </div>
              </div>
                               
                <div class="form-group">

                    <label class="sr-only" for="phone">Phone</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faPhoneAlt"></fa-icon></div>
                      </div>
                      <input type="text" formControlName="phone" class="form-control py-0" id="inlineFormInputGroup" placeholder="Phone" pattern="^\d{10}$" [ngClass]="{ 'is-invalid': submitted && f.phone.errors, 'is-valid': f.phone.valid && f.phone.touched }">
                      <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Phone number is required</div>
                      </div>
                      <div *ngIf="f.phone && f.phone.errors?.pattern" class="invalid-feedback">
                        10 digit Phone number, without space or dash
                      </div>
                    </div>
                </div>
                
                <div class="form-group">
                    <label class="sr-only" for="email">Email</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faEnvelope"></fa-icon></div>
                      </div>
                      <input type="email" formControlName="email" class="form-control py-0" name="email" id="email"  placeholder="Email" [pattern]="emailPattern"  [ngClass]="{ 'is-invalid': submitted && f.email.errors, 'is-valid': f.email.valid && f.email.touched }" >
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                      </div>
                      <div *ngIf="f.email && f.email.errors?.pattern" class="invalid-feedback"> 
                          Email address is not valid
                      </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sr-only" for="password">Password</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faKey"></fa-icon></div>
                      </div>
                      <input type="password" formControlName="password" class="form-control py-0" (change)="onPasswordChange()" name="password" id="password"  placeholder="Password" [pattern]="passwordPattern"  [ngClass]="{ 'is-invalid': submitted && f.password.errors, 'is-valid': f.password.valid && f.password.touched }" >
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                      </div>
                      <div *ngIf="f.password && f.password.errors?.pattern" class="invalid-feedback"> 
                          Password has to be 5 or more characters. 
                      </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="sr-only" for="confirmPassword">Password</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faKey"></fa-icon></div>
                      </div>
                      <input type="password" formControlName="confirmPassword" class="form-control py-0" (change)="onPasswordChange()" name="confirmPassword" id="confirmPassword"  placeholder="Confirm Password" [pattern]="passwordPattern"  [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors, 'is-valid': f.confirmPassword.valid && f.confirmPassword.touched }" >
                      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Please confirm password</div>
                      </div>
                      <div *ngIf="f.confirmPassword && f.confirmPassword.errors?.pattern" class="invalid-feedback"> 
                          Confirm Password has to be 5 or more characters. Less than 30 characters.
                      </div>
                      <div *ngIf="f.password && f.confirmPassword && f.confirmPassword.hasError('mismatch')" class="invalid-feedback">
                          Passwords do not match
                      </div>
                    </div>
                </div>

                <div *ngIf="error" class="alert alert-danger my-2 mt-3">{{error}}</div>

                <button [disabled]="loading" class="btn btn-success float-right">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Register <fa-icon class="l-icon" [icon]="faPaperPlane"></fa-icon>
                </button>
               
                
            </form>
        </div>
        
    </div>
</div>
