<div class="card mt-4">
  <h2 class="card-header display-6">Query - Property Maintenance</h2>
  <div class="card-body">

    <div class="form-group has-icon">
      <fa-icon class="form-control-feedback" [icon]="faBuilding"></fa-icon>
      <input type="text" name="property_code" class="form-control" placeholder="Property code">
    </div>
    <div class="form-group has-icon">
      <fa-icon class="form-control-feedback" [icon]="faMapPin"></fa-icon>
      <input class="form-control" type="text" placeholder="APT/Lot number" />
    </div>
    <div class="form-group has-icon" >
      <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
      <input class="form-control" type="text" placeholder="Your Pin code" />
    </div>
    <div class="form-group mt-4">
      <button [disabled]="fetching" class="btn btn-primary" (click)="queryMaintenance()">
        
        Query <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon> 
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>
      </button>
    </div>
  </div>
</div>

<div class="card mt-4">
  <div class="form-group prop-info">
    <input class="form-control" placeholder="Property" />
  </div>
  <div class="form-group prop-info">
    <input class="form-control" placeholder="Apt #" />
  </div>
  <hr />
  <div class="form-group prop-info">
    <h1 class="display-6">Outstanding Amount(s)</h1>
    <table class="table table-striped table-bordered">
      <thead>
        <th scope="col">Year</th>
        <th scope="col">Month</th>
        <th scope="col">Description</th>
        <th scope="col">Amount</th>
        <th scope="col">Fees</th>
      </thead>
      <tbody>
        <tr>
          <td>2022</td>
          <td>October</td>
          <td>February 2022 maintenance due</td>
          <td>$2,000.00</td>
          <td>$0.00
          </td>
        </tr>
      </tbody>

    </table>

    <div class="alert alert-success p-2">
      <p class="display-6">You are up to date!</p>
    </div>
 </div>

<hr />
    <h1 class="display-6">Payment(s)</h1>
    <table class="table table-striped table-bordered">
      <thead>
        <th scope="col">Period</th>
        <th scope="col">Description</th>
        <th scope="col">Trans. Date</th>
        <th scope="col">Payment Type</th>
      </thead>
      <tbody>
        <tr>
          <td>December 2022</td>
          <td>December 2022 maintenance payment</td>
          <td>25-Dec-2022</td>
          <td>OnlineTransfer</td> 
        </tr>
      </tbody>

    </table>

    <div class="mb-4">
      hello
    </div>
    <div class="mb-4">
      hello
    </div>
    <div class="mb-4">
      hello
    </div>
    <div class="mb-4">
      hello
    </div>



</div>
