import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@environments/environment';
import { AuthResponse } from './_models/responses';
import { Role } from './_models/role';
import { AuthenticationService } from './_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'billdeo';

  currentUser!: AuthResponse | null;
  VNUM: string | undefined;

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private _location: Location
    )
    {
      
    }

  checkHome() {
    return this.router.url == '/' || this.router.url.includes('/login') || this.router.url == '/change-password';
  }

  isAdmin() {
    //return this.currentUser;
    if (this.currentUser) {
        if (this.currentUser.access_token != undefined && this.currentUser.role != undefined) {
            return this.currentUser.role.includes(Role.ROLE_ADMIN);
        }

    }
    return false;
  }

  isSuper() {
    if (this.currentUser) {
        if (this.currentUser.access_token != undefined && this.currentUser.role != undefined) {
            return this.currentUser.role.includes(Role.ROLE_SUPER);
        }

    }
    return false;
  }

  goBack() {
      this._location.back();
  }

  ngOnInit() {
    // this.afMessaging.messages
    // .subscribe((message) => {
    //     this.messages = message;
    // });
    this.VNUM = environment.appVersion
  }


}
