import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, AuthenticationService } from '@app/_services';
import { Location } from '@angular/common';
import { faPaperPlane, faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm! : FormGroup;
  loading = false;
  submitted = false;
  error = '';
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  faPaperPlane = faPaperPlane;
  faArrowLeft = faArrowLeft;
  faEnvelope = faEnvelope; 

  constructor(        private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private _location: Location,
    private authenticationService: AuthenticationService) { }



  ngOnInit() {

    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
    });
  }

  goBack() {
    this._location.back();
  }

  openUsernameRequest() {
    this.router.navigate(['/forget-username']);
  }

  get f() { return this.forgetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.forgetPasswordForm.invalid) {

        this.loading = false;
        return;
    }

    var data = this.forgetPasswordForm.value;

    var request = { email: data.email};

    this.userService.requestPasswordReset(request).subscribe(
      {
        next: (data) => {
          this.toastr.success(data.message, 'Success');
          this.loading = false;
        },
        error: (error) => {
          this.toastr.error(error, 'Error');
          this.loading = false;
        }
      }
      // data => {
      //   this.toastr.success(data.message, 'Success');
      //   this.loading = false;
      // }, 
      // error => {
      //   this.toastr.error(error.message, 'Error');
      //   this.loading = false;
      // }
    )

   
  }

}
