import { Component, OnInit } from '@angular/core';
import { faKey, faHome, faPaperPlane, faSearch, faBell, faSignOutAlt,
  faTachometerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faBell = faBell;
  faSignOutAlt = faSignOutAlt;
  faSearch = faSearch;
  faTachometerAlt = faTachometerAlt;
  faBuilding = faBuilding;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.authenticationService.logout()
  }

}
