<div *ngIf="verified">
    <p class="alert-success alert p-4">
        Account verified successfully
        <span><fa-icon [icon]="faCircleCheck"></fa-icon></span>
    </p>
    <p><a class="btn btn-info" href="/home">Home</a></p>
</div>

<div *ngIf="!verified">
    <p class="alert alert-warning p-4">
        Failed to verify account
        <span><fa-icon [icon]="faTimesCircle"></fa-icon></span>
    </p>
    <p><a class="btn btn-info" href="/home">Home</a></p>
</div>
