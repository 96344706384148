<div class="container" *ngIf="action == 'create'">
    <h1 class="fs-2 fw-light mt-4">Add Payment</h1>
    <form [formGroup]="addPaymentForm" >
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Period</label>
            <input class="form-control col-3"  type="text" placeholder="Period" formControlName="period" [ngClass]="{ 'is-invalid': submitPaymentData && poa.period.errors, 'is-valid': poa.period.valid && poa.period.touched }"/>
        </div> -->
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Payment Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitPaymentData && poa.amount.errors, 'is-valid': poa.amount.valid && poa.amount.touched }"/>
            <!-- <span class="invalid-feedback" *ngIf="submitPaymentData && poa.amount.errors!.min!">Payment amount has to be at lease $1</span> -->
        </div>
        <div class="form-group my-4">
            <label>Choose Maintenance Period for Payment <span class="fw-bold">{{totalselected | currency}}</span></label>

            
        </div>
     
            <div class="my-1 alert-info alert mx-1 " *ngFor="let outstanding of outstandingMaintenance | orderBy: 'period'" >
                <p class="d-block">
                    <input class="form-check-input" type="checkbox" id="pcheck{{outstanding.id}}" value="{{outstanding.id}}" (change)="onCheckboxChange($event, outstanding.id)">
                    {{ outstanding.period | date: 'MMM-y':'UTC'}} <sup class="fw-bold">{{outstanding.obligationTypeId == 1 ? 'M' : 'F'}}</sup>
                </p>
                <p class="d-block mt-2">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ outstanding.amount | currency }}
                </p>
               
                
            </div>
    

        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Payment Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="period_date"  >
                <mat-error *ngIf="submitPaymentData && poa.period_date.errors">Please choose payment date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group my-4">
            <mat-form-field appearance="outline">
                <mat-label>Choose Effective Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="effective_date" >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-error *ngIf="submitPaymentData && poa.effective_date.errors">Please choose effective payment date</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker1">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Paid By</label>
            <input class="form-control col-3"  type="text" placeholder="Paid by e.g John Brown" formControlName="paid_by" [ngClass]="{ 'is-invalid': submitPaymentData && poa.paid_by.errors, 'is-valid': poa.paid_by.valid && poa.paid_by.touched }"/>
        </div>

        <div class="form-group">
            <label class="form-label">Payment Method</label>
            <select class="form-select form-control" formControlName="payment_method" [ngClass]="{ 'is-invalid': submitPaymentData && poa.payment_method.errors, 'is-valid': poa.payment_method.valid && poa.payment_method.touched }">
                <option value="1">Cash</option>
                <option value="2">Credit Card</option>
                <option value="3">Bank Transfer</option>    
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="description">Description</label>
            <input class="form-control col-3"  type="text" placeholder="Payment for Period" formControlName="description"  />
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lot/Unit Number</label>
            <input class="form-control col-3"  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
        </div>

        
    </form> 
</div>
<div class="container" *ngIf="action == 'edit'">
    <h1 class="fs-2 fw-light mt-4">Edit Payment</h1>
    <form [formGroup]="editPaymentForm" >
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Period</label>
            <input class="form-control col-3"  type="text" placeholder="Period" formControlName="period" [ngClass]="{ 'is-invalid': submitPaymentData && poa.period.errors, 'is-valid': poa.period.valid && poa.period.touched }"/>
        </div> -->
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitPaymentData && poa.amount.errors, 'is-valid': poa.amount.valid && poa.amount.touched }"/>
        </div>
        <div class="form-group my-4">
            <label>Choose Maintenance Period for Payment</label>
            <!-- <p>Show last 12 months as checkbox items</p> -->
            
        </div>
        <div class="row">
            <div class="my-1 alert-info alert mx-1 d-inline mx-2" *ngFor="let outstanding of outstandingMaintenance" >
                <input class="form-check-input" type="checkbox" id="pcheck{{outstanding.id}}" value="{{outstanding.id}}" (change)="onCheckboxChange($event, outstanding.id)">
                {{ outstanding.period | date: 'MMM-y':'UTC'}}
            </div>
        </div>
            <!-- <span class="my-1 alert-info alert mx-1 special-span" *ngFor="let outstanding of outstandingMaintenance" >
                <input class="form-check-input" type="checkbox" id="pcheck{{outstanding.id}}" value="{{outstanding.id}}" (change)="onCheckboxChange($event, outstanding.id)">
                {{ outstanding.period | date: 'MMM-y':'UTC'}}
            </span> -->
    

    

        <div class="form-group my-4">
            <mat-form-field appearance="fill">
                <mat-label>Choose Payment Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="period_date"  >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group my-4">
            <mat-form-field appearance="fill">
                <mat-label>Choose Effective Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="effective_date" >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker1">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="description">Description</label>
            <input class="form-control col-3"  type="text" placeholder="Payment for Period" formControlName="description"  />
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Paid By</label>
            <input class="form-control col-3"  type="text" placeholder="Paid by e.g John Brown" formControlName="paid_by" [ngClass]="{ 'is-invalid': submitPaymentData && poa.paid_by.errors, 'is-valid': poa.paid_by.valid && poa.paid_by.touched }"/>
        </div>

        <div class="form-group">
            <label class="form-label">Payment Method</label>
            <select class="form-select form-control" formControlName="payment_method">
                <option value="1">Cash</option>
                <option value="2">Credit Card</option>
                <option value="3">Bank Transfer</option>    
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lot/Unit Number</label>
            <input class="form-control col-3"  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
        </div>


        
    </form> 
</div>
<div class="container" *ngIf="action == 'view'">
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="fs-2 fw-light mt-4">View Payment</h1>
        <button (click)="onEditPayment()" class="mt-4 btn btn-primary">Edit <fa-icon [icon]="faPenToSquare"></fa-icon></button>
    </div>
    <form [formGroup]="viewPaymentForm" >
        <!-- <div class="form-group my-4">
            <label class="form-label" for="firstname">Period</label>
            <input class="form-control col-3"  type="text" placeholder="Period" formControlName="period" [ngClass]="{ 'is-invalid': submitPaymentData && poa.period.errors, 'is-valid': poa.period.valid && poa.period.touched }"/>
        </div> -->
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="amount" [ngClass]="{ 'is-invalid': submitPaymentData && poa.amount.errors, 'is-valid': poa.amount.valid && poa.amount.touched }"/>
        </div>
        <div class="form-group my-4">
            <label>Maintenance Period(s) paid with this Payment</label>
            <!-- <p>Show last 12 months as checkbox items</p> -->
            <div *ngFor="let period of payment.payment_obligations" class="alert alert-info mx-2">
                <span class="d-block">
                    {{ period.obligationperiod | date: 'MMM-y':'UTC' }}
                </span>
                <span class="d-block">
                    {{ period.amount | currency }}
                </span>
                     
            </div>
            
        </div>
     
            <span class="my-1 alert-info alert mx-1" *ngFor="let outstanding of outstandingMaintenance" >
                <input class="form-check-input" type="checkbox" id="pcheck{{outstanding.id}}" value="{{outstanding.id}}" (change)="onCheckboxChange($event,outstanding.id)">
                {{ outstanding.period | date: 'MMM-y':'UTC'}}
            </span>

            <div class="form-group my-4">
                <label class="form-label" for="description">Description</label>
                <input class="form-control col-3"  type="text" placeholder="Payment for Period" formControlName="description"  />
            </div>
    
            <div class="form-group my-4">
                <label class="form-label" for="period_date">Payment Date</label>
                <input class="form-control col-3"  type="text" placeholder="January-1-2020" formControlName="period_date"  />
            </div>

            <div class="form-group my-4">
                <label class="form-label" for="effective_date">Effective Date</label>
                <input class="form-control col-3"  type="text" placeholder="January-1-2020" formControlName="effective_date"  />
            </div>


        <div class="form-group my-4">
            <label class="form-label" for="firstname">Paid By</label>
            <input class="form-control col-3"  type="text" placeholder="Paid by e.g John Brown" formControlName="paid_by" [ngClass]="{ 'is-invalid': submitPaymentData && poa.paid_by.errors, 'is-valid': poa.paid_by.valid && poa.paid_by.touched }"/>
        </div>

        <div class="form-group">
            <label class="form-label">Payment Method</label>
            <select class="form-select form-control" formControlName="payment_method">
                <option value="1">Cash </option>
                <option value="2">Credit Card </option>
                <option value="3">Bank Transfer </option>    
            </select>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lot/Unit Number</label>
            <input class="form-control col-3"  type="text" placeholder="Lot/Unit number" formControlName="unitNumber" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
            <input class="form-control col-3"  type="hidden" formControlName="premiseId" readonly/>
        </div>

        <div class="form-group my-4">
            <label class="form-label" for="property">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property" formControlName="propertyName" [ngClass]="{ 'is-invalid': submitPaymentData && poa.premiseId.errors, 'is-valid': poa.premiseId.valid && poa.premiseId.touched }" readonly/>
        </div>

        
    </form> 
</div>
<div class="container" *ngIf="action == 'list'">
    <h1 class="fs-2 fw-light mt-4">List Payments</h1>
</div>
<div class="mt-4 d-flex container justify-content-between align-items-center">
    <button (click)="goBack()" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>

    <button *ngIf="action == 'create'" [disabled]="fetching" class="btn btn-primary" (click)="createPayment()" >
        Save <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>
    
    </button>

    <button *ngIf="action == 'edit'" [disabled]="fetching" class="btn btn-info" (click)="updatePayment()">
        Update <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>

    </button>
</div>
