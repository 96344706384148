import { Component, OnInit } from '@angular/core';
import { Property } from '@app/_models/property';
import { faSearch, faArrowRight, faHouse, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '@app/_services/property.service';
import { first } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-choose-property',
  templateUrl: './choose-property.component.html',
  styleUrls: ['./choose-property.component.css']
})
export class ChoosePropertyComponent {
  loading = false;
  
  searchText!: string;

  faSearch = faSearch;
  faArrowRight = faArrowRight;
  faHouse = faHouse;
  faBuilding = faBuilding;


  properties!: Property[];

  constructor(private propertyService: PropertyService) { }

  ngOnInit(): void {
    this.loading = true;
    this.propertyService.getAllProperties().pipe(first()).subscribe(
      {
        next: (properties) => {
          this.properties = properties;
          if (!environment.production) {
            console.log('Property data: ', this.properties);
          }
          
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Fail to load property data: ', error);
          }
          
        }
      }
    )
  }

  loadRequestPage(property: any) {

  }
}
