import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, 
        private toastr: ToastrService,
        private router: Router) { }

        intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(request).pipe(catchError(err => {
                if ([401, 403].includes(err.status)) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    //this.authenticationService.logout();
                    // Attempt to use refresh token
                    if (!environment.production) {
                        console.log('Using refresh token');
                    }

                    this.authenticationService.refresh().subscribe({
                        next: (data) => {
                            if (!environment.production) {
                                console.log('Refresh token data: ', data);
                            }
                            localStorage.setItem('currentUser', JSON.stringify(data));
                            localStorage.setItem('offlineUser', JSON.stringify(data));
                            location.reload();
                        },
                        error: (error) => {
                            if (!environment.production) {
                                console.log('Error from refresh: ', error);
                                
                            }
                            localStorage.removeItem('currentUser');
                            localStorage.clear();
                            this.router.navigate(['/login']);

                        }
                    });
                    // this.authenticationService.refresh().subscribe({
                    //     next: (data) => {
                    //         if (data.access_token) {
                    //             if (!environment.production) {
                    //                 console.log('Session refresh successful');  
                    //             }           
                    //             //this.toastr.success('Session refresh successful.','Success');
                    //             //location.reload();
                    //         } else {
                    //             //this.toastr.error('Failed to refresh session', 'Error');
                    //             //this.authenticationService.logout();
                    //             if (!environment.production) {
                    //                 console.log('Session refresh failed...',data);
                    //             }
                    //             this.authenticationService.logout();
                    //         }
                    //     },
                    //     error: (error) => {
                            
                    //         if (!environment.production) {
                    //             console.log('Session refresh failed.',error);
                    //         }
                    //         this.authenticationService.logout();
                    //     }
                    // }); 
                    
                    
                }
                //return new Error(err.error.message);
                const error = err.error.message || err.statusText;
                return throwError(() => error);
            }))
        }

   
}