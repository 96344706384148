import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faCircleArrowLeft, faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import {  Location } from '@angular/common';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { FinancialService } from '@app/_services/financial.service';
import { ToastrService } from 'ngx-toastr';
import { Obligation } from '@app/_models/obligation';

@Component({
  selector: 'app-manage-fees',
  templateUrl: './manage-fees.component.html',
  styleUrls: ['./manage-fees.component.css']
})

export class ManageFeesComponent {
  action: any;

  faCircleArrowLeft = faCircleArrowLeft;
  faPaperPlane = faPaperPlane;
  faPenToSquare = faPenToSquare;

  addFeeForm!: FormGroup;
  editFeeForm!: FormGroup;
  viewFeeForm!: FormGroup;

  fetching = false;
  submitFeeData = false;

  fee!: Obligation;

  selectedPremise: any;
  property: any;

  constructor(private route: ActivatedRoute,
     private _location: Location, private formBuilder: FormBuilder,
     private toastr: ToastrService, private financialService: FinancialService,
     private router: Router
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => this.action = params['action']);
  
    this.addFeeForm = this.formBuilder.group({
      amount: ['', Validators.required],
      period: ['', Validators.required],
      premiseId: ['', Validators.required],
      description: ['', Validators.required],
      propertyId: ['', Validators.required],
      unitNumber: [''],
      propertyName: [''],
      obligationTypeId: ['']
    });

    this.viewFeeForm = this.formBuilder.group({
      amount: [{value: '', disabled: true}, Validators.required],
      period: [{value: '', disabled: true}, Validators.required],
      premiseId: ['', Validators.required],
      description: [{value: '', disabled: true}, Validators.required],
      propertyId: ['', Validators.required],
      unitNumber: [{value: '', disabled: true}],
      propertyName: [{value: '', disabled: true}],
      obligationTypeId: [{value: '', disabled: true}, Validators.required]
    });

    this.editFeeForm = this.formBuilder.group({
      amount: ['', Validators.required],
      period: ['', Validators.required],
      premiseId: ['', Validators.required],
      description: ['', Validators.required],
      propertyId: ['', Validators.required],
      unitNumber: [''],
      propertyName: [''],
      obligationTypeId: ['']
    });

    let premstr = localStorage.getItem('premise');

    this.selectedPremise = JSON.parse(premstr ? premstr : '');

    let propertystr = localStorage.getItem('property');

    this.property = JSON.parse(propertystr ? propertystr : '');

    if (this.action == 'create' ) {
      
  
      this.addFeeForm.setValue({
        amount: '', period: '', obligationTypeId: 2, description: 'late fee ' + 'LOT/APT ' + this.selectedPremise.unit_number,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.selectedPremise.id, unitNumber: this.selectedPremise.unit_number
      });

    
      
  
    
    }

    if (this.action == 'view' || this.action == 'edit') {
      this.financialService.selectedFee$.subscribe((value) => {
        if (!environment.production) {
          console.log('Fee selected: ', value);
        }
      
        if (value.length == undefined) {
          let feestr = localStorage.getItem('stfee');
          this.fee = JSON.parse(feestr ? feestr : '');
        } else {
          this.fee = value;
        }
      });

      this.viewFeeForm.setValue({
        amount: this.fee.amount, period: moment(this.fee.period).format('MMMM DD YYYY'), obligationTypeId: this.fee.obligationTypeId, description: this.fee.description,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.selectedPremise.id, unitNumber: this.selectedPremise.unit_number
      });

      this.editFeeForm.setValue({
        amount: this.fee.amount, period: this.fee.period, obligationTypeId: this.fee.obligationTypeId, description: this.fee.description,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.selectedPremise.id, unitNumber: this.selectedPremise.unit_number
      });

    }
    
  }

  get foa() { return this.addFeeForm.controls; }
  get foe()  { return this.editFeeForm.controls; }

  goBack() {
    this._location.back();
  }

  createFee(): void {
    this.action = 'create';

    if (!this.addFeeForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.addFeeForm.value);
        console.log('Form not valid: ', this.addFeeForm);
      }

      return;
    }
    
    let formdata = this.addFeeForm.value;

    if (!environment.production) {
      console.log('Form data: Fee creation: ', formdata);
    }

    let request = {
      amount: formdata.amount,
      //period: formdata.period,
      period: moment(formdata.period).format('YYYY-MM-DD'),
      description: formdata.description,
      settled: false,
      premiseId: formdata.premiseId,
      propertyId: formdata.propertyId,
      obligationTypeId: formdata.obligationTypeId
    }

    console.log('Fee request obj: ', request);
    this.financialService.createObligation(request).subscribe(
      {
        next: (data) => {
          if (!environment.production) {
            console.log('Fee creation response: ', data);
          }
          this.toastr.success(data.message, 'Success');

          this.router.navigate(['/manage-premises/view'])
        }, 
        error: (error) => {
          this.toastr.error(error, 'Error');
          if (!environment.production) {
            console.log('Error: Fee creation: ', error);
          }
          
        }
      }
    );

  }

  onEditFee(): void {
    this.action = 'edit'
    this.router.navigate(['/manage-fees/edit']);
  }

  appendDescription(event: any) {

  }
}
