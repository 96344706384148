<div class="container" *ngIf="action == 'create'">
    <h1 class="fs-2 fw-light mt-4">Add Mortgage</h1>
    <form [formGroup]="addMortgageForm" >
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Payee</label>
            <input class="form-control col-3"  type="text" placeholder="e.g John Brown" formControlName="payee" [ngClass]="{ 'is-invalid': submitMortgageData && ma.payee.errors, 'is-valid': ma.payee.valid && ma.payee.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lender</label>
            <input class="form-control col-3"  type="text" placeholder="e.g NHT" formControlName="lender" [ngClass]="{ 'is-invalid': submitMortgageData && ma.lender.errors, 'is-valid': ma.lender.valid && ma.lender.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Interest Rate</label>
            <input class="form-control col-3"  type="text" placeholder="Interest rate e.g 7%" formControlName="interestRate" [ngClass]="{ 'is-invalid': submitMortgageData && ma.interestRate.errors, 'is-valid': ma.interestRate.valid && ma.interestRate.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 75000" formControlName="amount" [ngClass]="{ 'is-invalid': submitMortgageData && ma.amount.errors, 'is-valid': ma.amount.valid && ma.amount.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Premise Id</label>
            <input class="form-control col-3"  type="hidden" placeholder="Premise ID e.g 1" formControlName="premiseId" [ngClass]="{ 'is-invalid': submitMortgageData && ma.premiseId.errors, 'is-valid': ma.premiseId.valid && ma.premiseId.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property ID e.g 1" formControlName="property" [ngClass]="{ 'is-invalid': submitMortgageData && ma.property.errors, 'is-valid': ma.property.valid && ma.property.touched }"/>
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage Start Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate"  >
                <mat-error *ngIf="submitMortgageData && ma.startDate.errors">Please choose mortgage start date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage End Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="endDate"  >
                <mat-error *ngIf="submitMortgageData && ma.endDate.errors">Please choose mortgage end date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Status</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 1200" formControlName="status" [ngClass]="{ 'is-invalid': submitMortgageData && ma.status.errors, 'is-valid': ma.status.valid && ma.status.touched }"/>
        </div>
    
    </form>
</div>
<div class="container" *ngIf="action == 'edit'">
    <h1 class="fs-2 fw-light mt-4">Edit Mortgage</h1>
    <form [formGroup]="editMortgageForm" >
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Payee</label>
            <input class="form-control col-3"  type="text" placeholder="e.g John Brown" formControlName="payee" [ngClass]="{ 'is-invalid': submitMortgageData && me.payee.errors, 'is-valid': me.payee.valid && me.payee.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lender</label>
            <input class="form-control col-3"  type="text" placeholder="e.g NHT" formControlName="lender" [ngClass]="{ 'is-invalid': submitMortgageData && me.lender.errors, 'is-valid': me.lender.valid && me.lender.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Interest Rate</label>
            <input class="form-control col-3"  type="text" placeholder="Interest rate e.g 7%" formControlName="interestRate" [ngClass]="{ 'is-invalid': submitMortgageData && me.interestRate.errors, 'is-valid': me.interestRate.valid && me.interestRate.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 75000" formControlName="amount" [ngClass]="{ 'is-invalid': submitMortgageData && me.amount.errors, 'is-valid': me.amount.valid && me.amount.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Premise Id</label>
            <input class="form-control col-3"  type="hidden" placeholder="Premise ID e.g 1" formControlName="premiseId" [ngClass]="{ 'is-invalid': submitMortgageData && me.premiseId.errors, 'is-valid': me.premiseId.valid && me.premiseId.touched }"/>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property ID e.g 1" formControlName="property" [ngClass]="{ 'is-invalid': submitMortgageData && me.property.errors, 'is-valid': me.property.valid && me.property.touched }"/>
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage Start Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate"  >
                <mat-error *ngIf="submitMortgageData && me.startDate.errors">Please choose mortgage start date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage End Date</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="endDate"  >
                <mat-error *ngIf="submitMortgageData && me.endDate.errors">Please choose mortgage end date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker2">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Status</label>
            <input class="form-control col-3"  type="text" placeholder="e.g Active" formControlName="status" [ngClass]="{ 'is-invalid': submitMortgageData && me.status.errors, 'is-valid': me.status.valid && me.status.touched }"/>
        </div>
    
    </form>

</div>
<div class="container" *ngIf="action == 'view'">
    <div class="d-flex justify-content-between align-items-center">
        <h1 class="fs-2 fw-light mt-4">View Mortgage</h1>
        <button (click)="onEditMortgage()" class="mt-4 btn btn-primary">Edit <fa-icon [icon]="faPenToSquare"></fa-icon></button>
    </div>
    <form [formGroup]="viewMortgageForm" >
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Payee</label>
            <input class="form-control col-3"  type="text" placeholder="e.g John Brown" formControlName="payee" />
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Lender</label>
            <input class="form-control col-3"  type="text" placeholder="e.g NHT" formControlName="lender" />
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Interest Rate</label>
            <input class="form-control col-3"  type="text" placeholder="Interest rate e.g 7%" formControlName="interestRate" />
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Mortgage Amount</label>
            <input class="form-control col-3"  type="text" placeholder="Amount e.g 75000" formControlName="amount" />
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Premise Id</label>
            <input class="form-control col-3"  type="hidden" placeholder="Premise ID e.g 1" formControlName="premiseId" />
        </div>
    
        <div class="form-group my-4">
            <label class="form-label" for="firstname">Property</label>
            <input class="form-control col-3"  type="text" placeholder="Property ID e.g 1" formControlName="property" />
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage Start Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate"  >
                <mat-error *ngIf="submitMortgageData && me.startDate.errors">Please choose mortgage start date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4"  >
            <mat-form-field appearance="outline" [class.mat-form-field-invalid]="false">
                <mat-label>Choose Mortgage End Date</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="endDate"  >
                <mat-error *ngIf="submitMortgageData && me.endDate.errors">Please choose mortgage end date</mat-error>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker2">
                  </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    
        <div class="form-group my-4" *ngIf="me.status != undefined || me.status != null">
            <label class="form-label" for="status">Status</label>
            <input class="form-control col-3"  type="text" placeholder="e.g Active" formControlName="status" />
        </div>
    
    </form>

    
</div>

<div class="mt-4 d-flex container justify-content-between align-items-center">
    <button (click)="goBack()" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>

    <button *ngIf="action == 'create'" [disabled]="fetching" class="btn btn-primary" (click)="createMortgage()" >
        Save <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>
    
    </button>

    <button *ngIf="action == 'edit'" [disabled]="fetching" class="btn btn-info" (click)="updateMortgage()">
        Update <fa-icon *ngIf="!fetching" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="fetching" class="spinner-border spinner-border-sm mr-1"></span>

    </button>
</div>



