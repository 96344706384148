import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBed, faEye, faHashtag, faSearch, faCircleArrowLeft, faCirclePlus,
  faPaperPlane, faAngleDown, faAngleUp, faHomeAlt, faSms, faCircleXmark,
  faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Mortgage } from '@app/_models/mortgage';
import { FinancialService } from '@app/_services/financial.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-manage-mortgage',
  templateUrl: './manage-mortgage.component.html',
  styleUrls: ['./manage-mortgage.component.css']
})
export class ManageMortgageComponent {
  action: any;

  submitMortgageData = false;

  mortgage!: Mortgage;

  addMortgageForm!: FormGroup;
  editMortgageForm!: FormGroup;
  viewMortgageForm!: FormGroup;

  
  fetching = false;

  faPenToSquare = faPenToSquare;
  faCircleArrowLeft = faCircleArrowLeft;
  faPaperPlane = faPaperPlane;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router,
    private financialService: FinancialService, private _location: Location, private toastr: ToastrService) {
    
  }

  isEmpty(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
  
    return true
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => this.action = params['action']);


    this.addMortgageForm = this.formBuilder.group({  
      payee: [{ value: '', disabled: false }, Validators.required],
      lender: [{ value: '', disabled: false }, Validators.required],
      amount: ['',[ Validators.required, Validators.min(1)]],
      premiseId: [{ value: '', disabled: false }, Validators.required],
      interestRate: [{ value: '', disabled: false }, Validators.required],
      property: [{ value: '', disabled: false }, Validators.required],
      startDate: [{ value: '', disabled: false }, Validators.required],
      endDate: [{ value: '', disabled: false }, Validators.required],
      status: [{ value: '', disabled: false }, Validators.required]
    });

    this.editMortgageForm = this.formBuilder.group({
      payee: [{ value: '', disabled: false }, Validators.required],
      lender: [{ value: '', disabled: true}, Validators.required],
      amount: [{value: '', disabled: true},[ Validators.required, Validators.min(1)]],
      interestRate: [{ value: '', disabled: true }, Validators.required],
      premiseId: [{ value: '', disabled: true }, Validators.required],
      property: [{ value: '', disabled: true}, Validators.required],
      startDate: [{ value: '', disabled: false }, Validators.required],
      endDate: [{ value: '', disabled: false}, Validators.required],
      status: [{ value: '', disabled: true }, Validators.required],
    });

    this.viewMortgageForm = this.formBuilder.group({
      payee: [{ value: '', disabled: true }, Validators.required],
      lender: [{ value: '', disabled: true }, Validators.required],
      amount: [{value: '', disabled: true},[ Validators.required, Validators.min(1)]],
      interestRate: [{ value: '', disabled: true }, Validators.required],
      premiseId: [{ value: '', disabled: true }, Validators.required],
      property: [{ value: '', disabled: true }, Validators.required],
      startDate: [{ value: '', disabled: true }, Validators.required],
      endDate: [{ value: '', disabled: true }, Validators.required],
      status: [{ value: '', disabled: true }, Validators.required]
    });

    if (this.action == 'create') {
      this.addMortgageForm.setValue({
        payee: '', lender: '', amount: '', interestRate: '', premiseId: '', property: '', startDate: '',
        endDate: '', status: ''
      });
    }

    if (this.action == 'view' || this.action == 'edit') {
      this.financialService.selectedMortgage$.subscribe((value) => {
        
        
        if (value === undefined || this.isEmpty(value)) {
          let mortgagestr = localStorage.getItem('stmortgage');
          this.mortgage = JSON.parse(mortgagestr ? mortgagestr : '');
        } else {
          this.mortgage = value;
        }
        if (!environment.production) {
          console.log('Mortgage selected: View/Edit ', this.mortgage);
        }
      });

      this.editMortgageForm.setValue({
        payee: this.mortgage.payee, amount: this.mortgage.amount, lender: this.mortgage.lender, interestRate: this.mortgage.interestRate, startDate: this.mortgage.startDate,
        endDate: this.mortgage.endDate, premiseId: this.mortgage.premiseId, property: this.mortgage.property, status: this.mortgage.status
      });

      this.viewMortgageForm.setValue({
        payee: this.mortgage.payee, amount: this.mortgage.amount, lender: this.mortgage.lender, interestRate: this.mortgage.interestRate, startDate: this.mortgage.startDate,
        endDate: this.mortgage.endDate, premiseId: this.mortgage.premiseId, property: this.mortgage.property, status: this.mortgage.status
      });

    }
  }


  goBack() {
    this._location.back();
  }

  get ma() { return this.addMortgageForm.controls; }
  get me()  { return this.editMortgageForm.controls; }

  onEditMortgage() {
    this.action = 'edit';
    this.router.navigate(['/manage-mortgage/edit']);
  }

  updateMortgage(): void {
    // if (!this.editMortgageForm.valid) {
    //   if (!environment.production) {
    //     console.log('Form value: ',this.editMortgageForm.value);
    //     console.log('Form not valid: ', this.editMortgageForm);
    //   }

    //   return;
    // }

    let updateformdata = this.editMortgageForm.value;

    if (!environment.production) {
      console.log('Form data: Mortgage Update: ', updateformdata);
    }

    

    this.financialService.updateMortgage(this.mortgage.id, updateformdata).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Mortgage update response: ', data);
        }

        this.toastr.success(data.message, 'Success');


        this.router.navigate(['/manage-premises/view']);

      },
      error: (error) => {
        this.toastr.error(error, 'Error');
        if (!environment.production) {
          console.log('Error: Mortgage update: ', error);
        }
      }
    })
  }

  createMortgage(): void {
    this.action = 'create';

    this.submitMortgageData = true;

    if (!this.addMortgageForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.addMortgageForm.value);
        console.log('Form not valid: ', this.addMortgageForm);
      }

      return;
    }

  }
}
