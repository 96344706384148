import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { faTimesCircle, faCircleCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})

export class VerifyAccountComponent {

  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService) {
    
  }

  faTimesCircle = faTimesCircle;
  faCircleCheck = faCircleCheck;

  email = '';
  token = '';
  message: String | undefined;

  verified = false;

  ngOnInit() {

    this.route.params.subscribe((params: Params) => this.email = params['email']);
    this.route.params.subscribe((params: Params) => this.token = params['token']);

    this.authenticationService.verifyaccount(this.email,this.token).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Account verify success: ', data);
        }
        this.message = data.message;
        this.verified = true;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Account verify error: ', error);
        }
        this.message = error.message;
        this.verified = false;
      }
    })
  }
}
