<div class="container">
    <router-outlet></router-outlet>
</div>

<div class="footer text-center mt-4 d-print-none">
    <p>
        &copy; Billdeo 2024
        <a href="https://premises.billdeo.com" target="_top">App Website </a> v{{VNUM}}
    </p>
</div>

<div class="d-none text-center mt-4 fixed-bottom d-print-block">
    <p>
        &copy; Billdeo 2024
        <a href="https://premises.billdeo.com" target="_top">App Website </a> v{{VNUM}}
    </p>
</div>


 