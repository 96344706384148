<div class="container">
    <div *ngIf="action == 'index'">
        <div class="d-flex justify-content-between align-items-center">
            <h1 class="fs-2 fw-light mt-4">Choose Property</h1>
            <button (click)="goBack()" class="btn btn-primary">Back <fa-icon [icon]="faCircleArrowLeft"></fa-icon></button>
        </div>

        <div class="form-group has-search">
            <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
            <input type="text" name="search" [(ngModel)]="searchProperty" class="form-control" placeholder="Search by property name">
        </div>
    
        <ul class="list-group list-group-flush col-md-6">
            <li (click)="onSelectedProperty(property)" class="fs-3 text-primary list-group-item list-group-item-action d-flex justify-content-between align-items-center" 
            *ngFor="let property  of properties | filter: searchProperty ">
                <fa-icon *ngIf="property.property_type == 'house'" class="l-icon" [icon]="faHouse"></fa-icon> 
                <fa-icon *ngIf="property.property_type == 'strata'" class="l-icon" [icon]="faBuilding"></fa-icon> 
                <span class="text-black fw-lighter">{{property.name}} </span>
                <fa-icon [icon]="faCircleArrowRight"></fa-icon>
            </li>
    
        </ul>
    </div>
    <div *ngIf="action == 'view'">

        <div class="d-flex justify-content-between align-items-center">
            <h1 class="fs-2 fw-light mt-4">Compliance - Numbers</h1>
            <button (click)="goBack()" class="btn btn-primary">Back <fa-icon [icon]="faCircleArrowLeft"></fa-icon></button>
        </div>
        <form [formGroup]="complianceDataForm">
            <div class="form-group my-4">
                
                <label class="form-label" for="property_name">Property Name</label>
                <input class="form-control col-3"  type="text" placeholder="Property name" formControlName="property_name" />
            </div>
    
            <div class="form-group">
                <label class="form-label">Property Type</label>
                <select class="form-control" formControlName="property_type" >
                    <option value="strata">Strata</option>
                    <option value="house">House</option>
                    <option value="hotel">Hotel</option>
                </select>
            </div>
            <div class="form-group my-4">
                <label class="form-label" for="number_units">Number of units</label>
                <input class="form-control" placeholder="Number of units/apartments" formControlName="number_units" />
            </div>
    
            <div class="form-group my-4">
                <label class="form-label" for="number_units">Number of Units Compliant YTD</label>
                <input class="form-control" placeholder="Number of units/apartments" formControlName="compliantnumberytd" readonly/>
            </div>
            
            <div class="form-group my-4">
                <label class="form-label" for="number_units">Number of Units Compliant Current Period</label>
                <input class="form-control" placeholder="Number of units/apartments" formControlName="currentnumbercompliant"  readonly/>
            </div>
        
            <div class="form-group my-4">
                <label class="form-label" for="number_units">Percentage Compliance</label>
                <input class="form-control" placeholder="Number of units/apartments" formControlName="percentageytd"  readonly/>
            </div>
        </form>
        
    
        
    
    
    
        
    
        <h1 class="fs-2 fw-light mt-4">Compliance - Charts</h1>
        <div class="row my-4">
            <canvas baseChart
            [type]="'pie'"
            [datasets]="pieChartDatasets"
            [labels]="pieChartLabels"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [legend]="pieChartLegend">
          </canvas>
        </div>
        <div class="row my-4">
            <canvas baseChart
            [data]="barChartData"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [type]="'bar'">
            </canvas>
        </div>
        <div class="row my-4">
            <canvas baseChart width="400" height="400"
            [type]="'line'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend">
          </canvas>
        </div>
    
    </div>
    



</div>

