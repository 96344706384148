import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./../app/home/home.component";
import { ComplianceComponent } from "./compliance/compliance.component";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { LoginComponent } from "./login/login.component";
import { ManageFeesComponent } from "./manage-fees/manage-fees.component";
import { ManageMaintenanceComponent } from "./manage-maintenance/manage-maintenance.component";
import { ManagePaymentsComponent } from "./manage-payments/manage-payments.component";
import { ManagePremisesComponent } from "./manage-premises/manage-premises.component";
import { ManagePropertyComponent } from "./manage-property/manage-property.component";
import { QueryMaintenanceComponent } from "./query-maintenance/query-maintenance.component";
import { RegistrationComponent } from "./registration/registration.component";
import { PremisesComponent } from "./premises/premises.component";
import { AuthGuard } from "./_helpers";
import { Role } from "./_models/role";
import { VerifyAccountComponent } from "./verify-account/verify-account.component";
import { StatementComponent } from "./statement/statement.component";
import { ManageMortgageComponent } from "./manage-mortgage/manage-mortgage.component";

const routes: Routes = [
  {
    component: HomeComponent,
    path: "home",
    canActivate: [AuthGuard]
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "query-maintenance",
    component: QueryMaintenanceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "statement",
    component: StatementComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "manage-property/:action",
    component: ManagePropertyComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-mortgage/:action",
    component: ManageMortgageComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "manage-premises/:action",
    component: ManagePremisesComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-property",
    component: ManagePropertyComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    pathMatch: "full"
  },
  {
    path: "compliance",
    //redirectTo: "compliance/index",
    component: ComplianceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    pathMatch: "full"
  },
  {
    path: "manage-fees/:action",
    component: ManageFeesComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "premises",
    component: PremisesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "manage-payments/:action",
    component: ManagePaymentsComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-maintenance/:action",
    component: ManageMaintenanceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "compliance/:action",
    data: { roles: [Role.ROLE_ADMIN]},
    component: ComplianceComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "register", 
    component: RegistrationComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "verify/account/:email/:token",
    component: VerifyAccountComponent
  },
  {
    path: "forget-password",
    component: ForgetPasswordComponent
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}