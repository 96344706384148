import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from "./../app/app.routing.module";

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { RegistrationComponent } from './registration/registration.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { ToastrModule } from 'ngx-toastr';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { QueryMaintenanceComponent } from './query-maintenance/query-maintenance.component';
import { ChoosePropertyComponent } from './choose-property/choose-property.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentComponent } from './payment/payment.component';
import { ObligationComponent } from './obligation/obligation.component';
import { ManagePropertyComponent } from './manage-property/manage-property.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManagePremisesComponent } from './manage-premises/manage-premises.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { ManageFeesComponent } from './manage-fees/manage-fees.component';
import { ManageMaintenanceComponent } from './manage-maintenance/manage-maintenance.component';
import { OrderByPipe } from './_helpers/order.pipe';
import { ComplianceComponent } from './compliance/compliance.component';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgChartsModule } from 'ng2-charts';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import * as $ from "jquery";
import { TouchedErrorStateMatcher } from './_helpers/touched-error-state.matcher';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { PremisesComponent } from './premises/premises.component';
import { StatementComponent } from './statement/statement.component';
import { ManageMortgageComponent } from './manage-mortgage/manage-mortgage.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrderByPipe,
    LoginComponent,
    ChangePasswordComponent,
    QueryMaintenanceComponent,
    ForgetPasswordComponent,
    SplashScreenComponent,
    RegistrationComponent,
    ChoosePropertyComponent,
    DashboardComponent,
    PaymentComponent,
    ObligationComponent,
    ManagePropertyComponent,
    ManagePremisesComponent,
    ManagePaymentsComponent,
    ManageFeesComponent,
    ManageMaintenanceComponent,
    ComplianceComponent,
    VerifyAccountComponent,
    PremisesComponent,
    StatementComponent,
    ManageMortgageComponent
  ],
  imports: [
    BrowserModule, AppRoutingModule, FontAwesomeModule, NgbModule,
    NgxPaginationModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    ToastrModule.forRoot(),
    NgChartsModule,
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher },
    PercentPipe, CurrencyPipe, OrderByPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
